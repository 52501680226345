// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-sketchbook-algorithm-circle-algorithm-circle-js": () => import("./../../../content/sketchbook/algorithm-circle/algorithm-circle.js" /* webpackChunkName: "component---content-sketchbook-algorithm-circle-algorithm-circle-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locked-js": () => import("./../../../src/pages/locked.js" /* webpackChunkName: "component---src-pages-locked-js" */),
  "component---src-pages-sketchbook-js": () => import("./../../../src/pages/sketchbook.js" /* webpackChunkName: "component---src-pages-sketchbook-js" */),
  "component---src-templates-portfolio-page-js": () => import("./../../../src/templates/portfolio-page.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */)
}

